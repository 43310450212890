@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
body{
font-family: 'Poppins', sans-serif;
background-color: #ffffff;
margin: 0px;
padding: 0px;
height: 100%;
font-weight: 500;
}
*{
font-family: 'Poppins', sans-serif;
color-scheme: light;
-webkit-font-smoothing: antialiased;
}
html,body{
overflow-x: hidden;
}
input[type=number]::-webkit-inner-spin-button {
opacity: 1;
}
input{
outline: none;
box-shadow: none;
}
textarea{
outline: none;
box-shadow: none;
}
button{
font-family: 'Poppins', sans-serif;
font-weight: 500;
}
button:hover{
opacity: 80%;
-webkit-transition: ease-out 0.5s;
-moz-transition: ease-out 0.5s;
transition: ease-out 0.5s;
}
button:active{
opacity: 100%;
}
::-webkit-scrollbar {
width: 8px;
height: 8px;
}
::-webkit-scrollbar-track {
background: transparent;
border-radius: 50px;
}
::-webkit-scrollbar-thumb {
background: #929292;
border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
background: #777;
}
#title{
font-family: 'Source Serif Pro', serif;
font-weight: 700;
}
#title span{
font-family: 'Source Serif Pro', serif;
font-weight: 700;
}
#modal{
animation: realFadeIn .5s;
}
#cartModal{
animation: realFadeIn .5s;
}
#mailinglist{
animation: fadein .5s;
}
#shadow{
box-shadow: 0px 2px 8px rgb(0 0 0 / 16%);
}
#shadow-edit{
box-shadow: 0px 2px 8px rgb(0 0 0 / 16%);
animation: fadein .6s;
}
#noHover:hover{
opacity: 100% !important;
}
#bizModal{
animation: realFadeIn .5s;
}
@keyframes fadein {
0% {
opacity: 0;
transform: translateY(1000px);
}
50% {
opacity: 1;
transform: translateY(-50px);
}
100% {
transform: translateY(0);
}
}
@keyframes realFadeIn {
0% {
opacity: 0;
}
100% {
opacity: 1;
}
}


.react-reveal{
animation-fill-mode: backwards !important;
}
.lds-ellipsis {
display: inline-block;
position: relative;
width: 80px;
height: 80px;
}
.lds-ellipsis div {
position: absolute;
top: 33px;
width: 10px;
height: 10px;
border-radius: 50%;
background: #088C61;
animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
left: 6px;
animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
left: 6px;
animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
left: 32px;
animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
left: 56px;
animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
0% {
transform: scale(0);
}
100% {
transform: scale(1);
}
}
@keyframes lds-ellipsis3 {
0% {
transform: scale(1);
}
100% {
transform: scale(0);
}
}
@keyframes lds-ellipsis2 {
0% {
transform: translate(0, 0);
}
100% {
transform: translate(24px, 0);
}
}